<template>
  <div class="pipe">
    <div class="pipe-box">
      <div class="pipe-item pepe-left">
       
        <el-table
          :data="steelTableData"
          :header-cell-style="{ background: '#E5E9EE' }"
          border
          style="width: 100%"
        >
          <el-table-column type="index" align="center" width="50" label="序号"> </el-table-column>
          <el-table-column prop="dictName" align="center" label="告警类型">
        
          </el-table-column>

          <el-table-column prop="dataTypeName" align="center" label="数据类型">
          
          </el-table-column>
          <el-table-column prop="dataRange" align="center" label="数值范围（Nm³/KPa）">
          
          </el-table-column>
          <el-table-column prop="statusName" align="center"  label="状态">
          
          </el-table-column>
          <el-table-column prop="updateTime" align="center" label="更新时间">
          
          </el-table-column>
           <el-table-column prop="updateBy" align="center" label="更新人">
          
          </el-table-column>

          <el-table-column align="center" width="150" label="操作">
            <template #default="{ row}">
              <div class="tableBtn">
                <span class="btn" style="color: #1e9fff" @click="edit(row)"> 编辑 </span>
                <span class="btn" style="color: #ff5722" @click="getUpdate(row)"> {{row.statusName=='启用'?'停用':'启用'}} </span>
               
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog
      :visible.sync="dialogVisible"
      width="360px"
      title="编辑"
      :close-on-click-modal="false"
      @close="clearnF('form')"
      custom-class="dialog-class"
    >
    <el-form ref="addform" :model="form" label-width="80px"  size="mini" >
        <div >
          <el-form-item label="告警类型:" >
            {{form.dictName}}
          </el-form-item>
          <el-form-item label="数据类型:" >
            {{form.dataTypeName}}
          </el-form-item>
          <el-form-item label="数据范围:"  >
             <el-input  v-model="form.rangeStart" style="width:100px" type="digit" @input="handleInput1" ></el-input>
             <span>-</span>
             <el-input  v-model="form.rangeEnd" style="width:100px;"   type="digit" @input="handleInput2" ></el-input>
             {{form.dataTypeName=='瞬时流量'?'Nm³':'KPa'}}
          </el-form-item>
         
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button style="margin-right:10px" @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveadd">保存</el-button>
     </div>
    </el-dialog>
   
    </div>
  </div>
</template>

<script>
import {set_queryConfig,set_setGeneralStatus,set_update} from '../apis/base'
export default {
  data() {
    return {
      steelTableData: [],
      peTableData: [],
      steeloptions: [],
      steeloptionsAlter: [],
      peptions: [],
      peptionsAlter: [],
      copyRow: new Map(),
      peCopyRow: new Map(),
      dialogVisible:false,
      form:{rangeStart:'',rangeEnd:''}
     
    }
  },
  computed: {
  },
  mounted() {
    this.getList()
  },
  methods: {
    /*
     *@description: 获取字典
     *@author: 黄丽玲
     */
 
    
    async getList() {
      let res = await set_queryConfig()
      this.steelTableData = res.data
    },
    
    edit(row){
      let rangeArr = row.dataRange.split('-')
        this.form.rangeStart = rangeArr[0]
        this.form.rangeEnd = rangeArr[1]
        this.form.dictName = row.dictName 
        this.form.dataTypeName = row.dataTypeName 
        this.form.id = row.id

        this.dialogVisible = true
    },
    clearnF(){
        this.form = {rangeStart:'',rangeEnd:''};
    },
    saveadd(){
        if(parseInt(this.form.rangeStart)>parseInt(this.form.rangeEnd)){
            this.$message.warning('数据范围错误')
            return
        }
        set_update(this.form).then(res=>{
            if (res.code === 200) {
                this.$message.success(res.msg);
                this.getList();
                this.dialogVisible = false
                this.clearnF()
            } else {
                this.$message.error(res.msg);
            }
        })
    },
    getUpdate(row){
        let namecz = row.statusName=='启用'?'停用':'启用';
        this.$confirm(`是否确认${namecz}该条数据?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        set_setGeneralStatus({id:row.id}).then((res) => {
          this.$message.success(res.data);
          this.getList();
        });
      });
    },
    handleInput1(e) {
     this.$nextTick(() => {
        let val = this.form.rangeStart.toString();
        val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
        val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
 
        var len1 = val.substr(0, 1);
        var len2 = val.substr(1, 1);
        //如果第一位是0，第二位不是点，就用数字把点替换掉
        if (val.length > 1 && len1 == 0 && len2 != ".") {
          val = val.substr(1, 1);
        }
        //第一位不能是.
        if (len1 == ".") {
          val = "";
        }
 
        val = val.replace(/^0+\./g, '0.');
        val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
        val = (val.match(/^\d*(\.?\d{0,3})/g)[0]) || ''
 
        if (val.includes(".")) {
          let numDian = val.toString().split(".")[1].length;
          if (numDian === 2) {
            //this.moneyMaxLeng = val.length;
          }
        } else {
         // this.moneyMaxLeng = 8;
        }
        this.form.rangeStart = val;
      });
  },
    handleInput2(e) {
     this.$nextTick(() => {
        let val = this.form.rangeEnd.toString();
        val = val.replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
        val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
 
        var len1 = val.substr(0, 1);
        var len2 = val.substr(1, 1);
        //如果第一位是0，第二位不是点，就用数字把点替换掉
        if (val.length > 1 && len1 == 0 && len2 != ".") {
          val = val.substr(1, 1);
        }
        //第一位不能是.
        if (len1 == ".") {
          val = "";
        }
 
        val = val.replace(/^0+\./g, '0.');
        val = val.match(/^0+[1-9]+/) ? val = val.replace(/^0+/g, '') : val
        val = (val.match(/^\d*(\.?\d{0,3})/g)[0]) || ''
 
        if (val.includes(".")) {
          let numDian = val.toString().split(".")[1].length;
          if (numDian === 2) {
            //this.moneyMaxLeng = val.length;
          }
        } else {
         // this.moneyMaxLeng = 8;
        }
        this.form.rangeEnd = val;
      });
  },
   
  }
}
</script>

<style lang="scss" scoped>
::v-deep{
  .el-dialog__body{padding-bottom:0}
}
.pipe{
    background-color: #fff;
    padding: 10px 15px;
    border-top: #eeeeee 1px solid;
}
.pipe-box {
  display: flex;
  .btns {
    margin-bottom: 10px;
  }
  .pipe-item {
    flex: 1;
  }
  .pepe-left {
    margin-right: 30px;
  }
  .tableBtn {
    .btn {
      cursor: pointer;
      margin: 0 12px;
      &.up {
        color: #1e9fff;
      }
      &.down {
        color: #0dba7b;
      }
    }
  }
}
</style>
